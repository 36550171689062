<template>
  <ClientOnly>
    <el-dropdown
      ref="myDropdownRef"
      v-bind="$attrs"
      :popper-class="`w-${width.replace('px', '')} ${data.searchKey === 'type' ? 'my-el-dropdown' : 'my-el-dropdown normal-dropdown'}`"
      :hide-on-click="false"
      trigger="click"
    >
      <template v-if="type === 'multiple'">
        <div
          :class="['el-dropdown-link', { active: Object.keys(chooseItem) && Object.keys(chooseItem)[0] }]"
          :style="{
            'min-width': props.width,
            height: props.height,
            'line-height': props.height
          }"
        >
          <template v-if="Object.keys(chooseItem) && Object.keys(chooseItem)[0]">
            <div class="mt-7 flex h-20 items-center">
              <div class="ensus-item flex items-center text-gray-light">
                <span class="text-12 leading-none">{{
                  data.searchKey === 'type'
                    ? `${chooseItem[Object.keys(chooseItem)[0]].firstname}/${chooseItem[Object.keys(chooseItem)[0]].name}`
                    : chooseItem[Object.keys(chooseItem)[0]].name
                }}</span>
                <i class="iconfont icon-delete ml-5 text-12 leading-none" @click="deleteItem(data.searchKey, chooseItem[Object.keys(chooseItem)[0]])"></i>
              </div>

              <div class="ensus-item ml-5 text-12 text-gray-light">+{{ Object.keys(chooseItem).length }}</div>
            </div>
          </template>
          <template v-else>
            <span>{{ data.name }}</span>
          </template>
          <i class="arrow"></i>
        </div>
      </template>
      <template v-else>
        <div
          :class="['el-dropdown-link', { active: chooseItem.name }]"
          :style="{
            'min-width': props.width,
            height: props.height,
            'line-height': props.height
          }"
        >
          <span
            >{{ chooseItem.name ? chooseItem.name : data.name }}
            <i v-if="chooseItem.name && needReset" class="iconfont icon-delete hover:color-[#0252D9] text-gray" @click="reset(chooseItem)"></i>
          </span>
          <i class="arrow"></i>
        </div>
      </template>

      <template #dropdown>
        <template v-if="type === 'multiple'">
          <el-dropdown-menu class="my-el-dropdown-menu--multiple" :class="data.searchKey === 'type' ? '' : 'my-el-dropdown-menu'" trigger="click">
            <el-dropdown-item v-for="(item, i) in selfData.list" :key="i">
              <template v-if="item.searchKey === 'type'">
                <div class="second-data flex items-center">
                  <el-checkbox v-model="item.isChecked" :indeterminate="item.isIndeterminate" @change="chooseType('first', item, item)">
                    <span>{{ item.name }}</span>
                  </el-checkbox>
                  <i v-if="item.list" class="arrow"></i>
                </div>

                <div
                  v-if="item.list"
                  class="second-list overflow-y-auto overflow-x-hidden"
                  :style="{
                    width: item.key === 'CSGO_Tool_Sticker' || item.key === 'CSGO_Type_WeaponCase' ? '280px' : width,
                    left: `${width.replace('px', '') - 2}px`
                  }"
                >
                  <p v-for="(child, j) in item.list" :key="j" class="text-color-lightgray p-10 text-14">
                    <el-checkbox v-model="child.isChecked" @change="chooseType('second', item, child)">
                      {{ child.name }}
                    </el-checkbox>
                  </p>
                </div>
              </template>

              <template v-else>
                <el-checkbox v-model="item.isChecked" @change="chooseData(item)">
                  <span
                    :style="{
                      color: item.color
                    }"
                  >
                    {{ item.name }}
                  </span>
                </el-checkbox>
              </template>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>

        <template v-else>
          <el-dropdown-menu :class="[data.searchKey === 'type' ? '' : 'my-el-dropdown-menu', data.dropdownMenuClass]">
            <el-dropdown-item v-for="(item, i) in selfData.list" :key="i" @click.stop="chooseData(item)">
              <template v-if="item.searchKey === 'type'">
                <div class="second-data flex items-center">
                  <span>{{ item.name }}</span>
                  <i v-if="item.list" class="arrow"></i>
                </div>

                <div
                  v-if="item.list"
                  class="second-list overflow-y-auto overflow-x-hidden"
                  :style="{
                    width: item.key === 'CSGO_Tool_Sticker' || item.key === 'CSGO_Type_WeaponCase' ? '280px' : width,
                    left: `${width.replace('px', '') - 2}px`
                  }"
                >
                  <p v-for="(child, j) in item.list" :key="j" class="text-color-lightgray p-10 text-14" @click.stop="chooseData(child)">
                    {{ child.name }}
                  </p>
                </div>
              </template>

              <template v-else>
                <p
                  :style="{
                    color: item.color
                  }"
                >
                  <i v-if="item.iconfont" :class="['iconfont', item.iconfont, `text-${item.fontSize}`]"></i>
                  {{ item.name }}
                </p>
              </template>
            </el-dropdown-item>
            <template v-if="data.extend">
              <div class="divider"></div>
              <div class="px-10 py-15" :style="data.extendStyle">
                {{ data.extend }}
              </div>
            </template>
          </el-dropdown-menu>
        </template>
      </template>
    </el-dropdown>
  </ClientOnly>
</template>

<script setup>
import { ref } from 'vue'

const emit = defineEmits(['updateData'])
const myDropdownRef = ref(null)

const props = defineProps({
  type: {
    type: String,
    default: 'single'
  },
  data: {
    type: Object,
    default: () => ({})
  },
  width: {
    type: String,
    default: '110px'
  },
  height: {
    type: String,
    default: '40px'
  },
  needReset: {
    type: Boolean,
    default: false
  }
})

let chooseItem = ref({})
const selfData = reactive(JSON.parse(JSON.stringify(props.data)))

// 筛选
function chooseData(item) {
  if (props.type === 'multiple') {
    // 多选
    if (chooseItem.value[item.key]) {
      delete chooseItem.value[item.key]
    } else {
      chooseItem.value[item.key] = item
    }

    emit('updateData', chooseItem.value, item.searchKey)
  } else {
    // 单选
    chooseItem.value = item
    myDropdownRef.value.handleClose()
    emit('updateData', item)
  }
}
function chooseType(type, firstdata, data) {
  if (type === 'first') {
    if (data.isChecked) {
      data.isIndeterminate = false
      if (data.list) {
        data.list.forEach(item => {
          item.isChecked = true
          chooseItem.value[item.key] = item
          chooseItem.value[item.key].firstkey = firstdata.key
          chooseItem.value[item.key].firstname = firstdata.name
        })
      } else {
        chooseItem.value[data.key] = data
        chooseItem.value[data.key].firstkey = firstdata.key
        chooseItem.value[data.key].firstname = firstdata.name
      }
    } else {
      data.isIndeterminate = false
      if (data.list) {
        data.list.forEach(item => {
          item.isChecked = false
          delete chooseItem.value[item.key]
        })
      } else {
        delete chooseItem.value[data.key]
      }
    }
  } else if (type === 'second') {
    // 二级
    if (data.isChecked) {
      chooseItem.value[data.key] = data
      chooseItem.value[data.key].firstkey = firstdata.key
      chooseItem.value[data.key].firstname = firstdata.name

      let checkLength = 0
      for (let key in chooseItem.value) {
        if (chooseItem.value[key].firstkey === firstdata.key) {
          checkLength++
        }
      }

      firstdata.isIndeterminate = false
      if (checkLength === firstdata.list.length) {
        firstdata.isChecked = true
      } else {
        firstdata.isIndeterminate = true
      }
    } else {
      delete chooseItem.value[data.key]

      let resetCheck = true
      for (let key in chooseItem.value) {
        if (chooseItem.value[key].firstkey === firstdata.key) {
          resetCheck = false
        }
      }

      if (resetCheck) {
        firstdata.isChecked = false
        firstdata.isIndeterminate = false
      } else {
        firstdata.isIndeterminate = true
      }
    }
  }

  emit('updateData', chooseItem.value, firstdata.list ? firstdata.list[0].searchKey : firstdata.searchKey)
}

// 删除
function deleteItem(searchKey, data) {
  delete chooseItem.value[data.key]

  if (searchKey === 'type') {
    let resetCheck = true
    for (let key in chooseItem.value) {
      if (chooseItem.value[key].firstkey === data.firstkey) {
        resetCheck = false
      }
    }

    props.data.list.forEach(list => {
      if (list.key === data.firstkey) {
        console.log('list', list)
        list.list.forEach(item => {
          if (item.key === data.key) {
            list.isIndeterminate = true
            item.isChecked = false
          }
        })

        if (resetCheck) {
          list.isIndeterminate = false
          list.isChecked = false
        }
      }
    })
  } else {
    props.data.list.forEach(item => {
      if (item.key === data.key) {
        item.isChecked = false
      }
    })
  }

  emit('updateData', chooseItem.value, searchKey)
}
function reset(item) {
  resetData()
  emit('updateData', {
    searchKey: item.searchKey,
    key: ''
  })
}
// 重置
function resetData() {
  if (props.type === 'multiple') {
    selfData.list.forEach(list => {
      list.isIndeterminate = false
      list.isChecked = false
      if (list.list) {
        list.list.forEach(item => {
          item.isChecked = false
        })
      }
    })
  }

  chooseItem.value = {}
}

defineExpose({ resetData })
</script>

<style lang="scss">
@import url('~/assets/styles/_color.scss');

.my-el-dropdown {
  min-width: 110px;
  font-size: 14px;
  background: #ffffff;
  border: 1px solid #dddfe7;
  border-radius: 4px;
  margin-top: -10px;
  margin-bottom: -10px;

  &.normal-dropdown {
    .el-dropdown-menu {
      overflow-y: auto;
    }
  }

  .el-scrollbar {
    overflow: visible;
  }
  .el-scrollbar__wrap {
    overflow: visible;
  }
  .el-scrollbar__bar {
    display: none;
  }

  .el-popper__arrow {
    display: none;
  }

  .el-dropdown-menu {
    padding: 0;
    max-height: 470px;

    &__item {
      padding: 8px 10px;
      font-size: 14px;
      color: $light-gray-color;
      line-height: 20px;
      position: relative;

      .second-data {
        width: 100%;

        .arrow {
          border-top: 6px solid transparent;
          border-left: 6px solid $light-blue-color;
          border-bottom: 6px solid transparent;
          position: absolute;
          right: 0;
        }
      }

      .second-list {
        display: none;
        width: 110px;
        max-height: 300px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #dddfe7;
        position: absolute;
        left: 108px;
        top: 0;

        p {
          &:hover {
            color: #252525;
          }
        }
      }

      &:hover {
        color: #252525;
        background: #dddfe7;

        .second-list {
          display: block;
        }
      }
    }
  }

  .my-el-dropdown-menu {
    overflow-y: auto;
  }

  .my-el-dropdown-menu--multiple {
    .el-dropdown-menu__item {
      padding: 0;

      .el-checkbox {
        width: 100%;
        padding: 0 10px;
        height: 36px;
        color: $light-gray-color;
      }

      .second-list {
        p {
          padding: 0;

          .el-checkbox {
            width: 100%;
            padding: 0 10px;
            height: 36px;
            color: $light-gray-color;
          }

          &:hover {
            .el-checkbox {
              color: #252525;
            }
          }
        }
      }

      &:hover {
        .el-checkbox {
          color: #252525;
        }
      }
    }
  }
  .divider {
    border: 1px solid #dddfe7;
  }
}
</style>

<style lang="scss" scoped>
@import url('~/assets/styles/_color.scss');

.el-dropdown-link {
  font-size: 14px;
  color: #979cae;
  padding: 0 10px;
  background: #ffffff;
  border: 1px solid #dddfe7;
  border-radius: 10px;
  outline: 0 none !important;
  position: relative;

  .ensus-item {
    padding: 0 5px;
    height: 20px;
    line-height: 20px;
    background: $border-gray-color;
    border-radius: 10px;
  }

  &.active,
  &:hover {
    color: $primary-color;
    border-color: $primary-color;
  }

  .arrow {
    width: 0;
    height: 0;
    border-bottom: 12px solid #dddfe7;
    border-left: 12px solid transparent;
    position: absolute;
    right: 6px;
    bottom: 7px;
  }
}
</style>
